import React, { useState, useEffect } from "react";
import { Form, Button, Space, Radio, Checkbox, Col, Row, Input } from "antd";
import Axios from "axios";

import "./Question.css";

function Question() {
  const [item, setItem] = useState(null);
  const [choicesType, setChoicesType] = useState(null);
  const [question, setQuestion] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [image, setImage] = useState(null);

  const [itemNumber, setItemNumber] = useState(0);
  const [choiceValue, setChoiceValue] = useState("");
  const [arrayValue, setArrayValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [answer, setAnswer] = useState([]);
  const [finalAnswers, setFinalAnswers] = useState([]);

  useEffect(() => {
    console.log();
    Axios.get("https://preboards-api.onrender.com/api/preboards").then(
      (response) => {
        setItem(response.data[itemNumber]);
        setChoicesType(response.data[itemNumber].choicesType);
        setQuestion(response.data[itemNumber].question);
        setQuestionNumber(response.data[itemNumber].questionNumber);
        setItemId(response.data[itemNumber]._id);
        setImage(response.data[itemNumber].image);
      }
    );
  }, [itemNumber]);

  const onChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  const choices = item ? (
    item.choices.map((choiceItem) => {
      return (
        <>
          {choicesType === "radio" ? (
            <Radio value={choiceItem} className="choices">
              {choiceItem}
            </Radio>
          ) : choicesType === "checkbox" ? (
            <Col span={8}>
              <Checkbox value={choiceItem}>{choiceItem}</Checkbox>
            </Col>
          ) : (
            <Input onChange={onChangeInput} />
          )}
        </>
      );
    })
  ) : (
    <h1>no Data</h1>
  );

  const onChange = (e) => {
    setChoiceValue(e.target.value);
  };

  const onCheckChange = (checkedValues) => {
    setArrayValue(checkedValues);
  };

  const answerValue =
    choicesType === "radio"
      ? choiceValue
      : choicesType === "checkbox"
      ? arrayValue
      : inputValue;

  const submit = () => {
    setFinalAnswers(answer);
  };

  const storeAnswer = () =>
    Axios.put(`https://preboards-api.onrender.com/api/preboards/${itemId}`, {
      ...item,
      inputAnswer: answerValue,
    }).then((response) => {
      setItemNumber((prevItemNumber) => prevItemNumber + 1);
    });

  return (
    <div className="question-container">
      <div className="content">
        <h4>QUESTION {questionNumber}</h4>
        <div className="question-content">
          <p>{question}</p>

          {image && (
            <div>
              <a href={image.href}>
                <img src={image.src} />
              </a>
            </div>
          )}
          <Form>
            <Form.Item
              rules={[{ required: true, message: "Please select an answer" }]}
            >
              {choicesType === "radio" ? (
                <Radio.Group onChange={onChange} value={choiceValue}>
                  {choices}
                </Radio.Group>
              ) : choicesType === "checkbox" ? (
                <Checkbox.Group onChange={onCheckChange} value={arrayValue}>
                  <Row>{choices}</Row>
                </Checkbox.Group>
              ) : (
                <div>{choices}</div>
              )}
            </Form.Item>
          </Form>
        </div>
        <div className="content-buttons">
          <Space wrap>
            <Button
              type="primary"
              onClick={() => {
                storeAnswer();
              }}
              className="nextBtn"
            >
              NEXT
            </Button>
            {itemNumber === 99 && (
              <Button onClick={() => submit()}>Submit</Button>
            )}
          </Space>
        </div>
      </div>
    </div>
  );
}
export default Question;
