import React from "react";
import Question from "./Question";

import "./Content.css";

function Content() {
  return (
    <div className="content-container">
      <Question />
    </div>
  );
}

export default Content;
