import React from "react";
import SidebarList from "./SidebarList";
import "./Sidebar.css";

function sidebar() {
  return (
    <div className="sideBar">
      <SidebarList />
    </div>
  );
}

export default sidebar;
