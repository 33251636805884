import React from "react";
import "./Sidebar.css";

function SidebarList() {
  return (
    <div className="sidebar-list">
      <h3>
        Welcome, <br />
        LALAINE JEAN LORENTE, USRN
      </h3>
      <h5>USER</h5>
      <h5>ADMIN</h5>
    </div>
  );
}

export default SidebarList;
